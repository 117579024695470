import useIsServer from "@/Hooks/useIsServer";
import StartBuildings from "@resources/main-buildings4.jpg?format=webp&quality=75&imagetools";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useState } from "react";
import MainApartmentViewEstateA from "./MainApartmentViewEstateA";
import MainApartmentViewEstateB from "./MainApartmentViewEstateB";
import { router } from "@inertiajs/react";

const MainApartmentViewRedirect = () => {
    const [selectedBuilding, setSelectedBuilding] = useState<number>(0);
    const [visibleInfo, setVisibleInfo] = useState<boolean>(true);

    const isServer = useIsServer();

    const highlightFloor = (floor: string) => {
        if (!isServer) {
            const rect = document.getElementById(floor);

            if (rect) {
                // @ts-ignore
                rect.style.fill = rect.getAttribute("fill")?.replace('transparent', '#00405350')
            }
        }
    }

    const unHighlightFloor = (floor: string) => {
        if (!isServer) {
            const rect = document.getElementById(floor);

            if (rect) {
                // @ts-ignore
                rect.style.fill = rect.getAttribute("fill")?.replace('#00405350', 'transparent')
            }
        }
    }

    return (
        <div className="w-full h-full relative">
            {selectedBuilding !== 0 &&
                <div className="flex absolute py-5 gap-x-2 px-4 glass-style rounded-[32px] top-4 left-4 maxMd:hidden transition-all ease-in-out duration-300 hover:translate-x-4 hover:opacity-70">
                    <button className="text-primary font-bold flex items-center gap-x-2 " type="button" onClick={() => setSelectedBuilding(0)}>
                        <Icon icon={`solar:alt-arrow-down-outline`} width={24} className="rotate-90" />
                        <p className="text-p leading-p text-primary uppercase font-bold">Powrót</p>
                    </button>
                </div>
            }
            <div className="flex absolute py-5 gap-x-2 px-4 glass-style rounded-[32px] top-4 right-4 maxMd:hidden">
                <p className="text-p leading-p text-primary uppercase font-bold">
                    Wybierz budynek aby przejść do wyszukiwarki mieszkań
                </p>
                <button className="text-primary font-bold" type="button" onClick={() => setVisibleInfo(false)}>
                    <Icon icon={`mi:close`} width={24} />
                </button>
            </div>
            {selectedBuilding === 0 &&
                <svg className="w-full h-auto" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2000 988">
                    <g>
                        <g id="Warstwa_1">
                            <image width="2000" height="988" xlinkHref={StartBuildings} />
                            <path className="cursor-pointer transition-all ease-in-out duration-300" id="F01" onMouseEnter={() => highlightFloor('F01')} onMouseLeave={() => unHighlightFloor('F01')} onClick={() => router.visit(route('frontend.apartments', { _query: { view: 2, estateB: 1 } }))} fill="transparent" d="M1107.7,432.9l123.9-1,1,2.9v3l96.7-.6v-1.8l119.5-1.2v1.3l139.4-1.3,3-3.4,30.9-.4,4.8-5.4h4.8l3-3.3,12.5-.3v-5.7c-.1,0,17.8-21.7,17.8-21.7l59.1-.8v-4.4l22.6-23.7,158.4-1.6,1.4,452h-2v48.2h-216.6s-142.4-14.7-143.2-14.7h-437.2v-416.2Z" />
                            <path className="cursor-pointer transition-all ease-in-out duration-300" id="F00" onMouseEnter={() => highlightFloor('F00')} onMouseLeave={() => unHighlightFloor('F00')} onClick={() => router.visit(route('frontend.apartments', { _query: { view: 1, estateA: 1 } }))} fill="transparent"  d="M919.6,877.2v-32.8l15-1.5-.4-298.3-3.8-2.6h-9.9v-6.9l-10.8-8.2h-7.1l-35.7-25.6-120.2.6-4.1,2.9-342.7-8.9-.3,1.6-51.8-1.4v-2.5l-14.8-.4v1.7h-10s-.3,16.8-.3,16.8l-43.4-.9v-1.8l-18.8-.5v1.5h-10.1s-.1,2.3,0,2.1-43.6-.8-43.6-.8v-7.3l-1.3-2.2h-9.2v.5h-14l-.2.2h-2.2v8.1h-21v9h-27.7v6.1l-17,2.3s-2.3,2.2-2.1,4.1h-7.4v7.3h-24.2v27.5h-14.1v2.3l2.9,2.3h-6.9v3l7.9,6.7v14.2h-8.3v316l394.1-9.9,338.2-8.5,125.4-15.9Z" />
                        </g>
                    </g>
                </svg>
            }
            {selectedBuilding === 1 &&
                <MainApartmentViewEstateA />
            }
            {selectedBuilding === 2 &&
                <MainApartmentViewEstateB />
            }
        </div>
    )
}

export default MainApartmentViewRedirect;