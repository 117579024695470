import { Link } from "@inertiajs/react";
import Column from "../Grid/Column";
import Container from "../Grid/Container";
import Row from "../Grid/Row";
import MainApartmentView from "./MainApartmentView";
import MainApartmentViewRedirect from "./MainApartmentViewRedirect";

const SearchWithRedirect2D = ({ additionalButtons = false, heading = 'Znajdź swoje wymarzone mieszkanie' } ) => {
    return (
        <>
            {additionalButtons &&
                <Container>
                    <Row>
                        <Column>
                            <h2 className="text-h3 leading-h3 text-primary font-extrabold mb-8 text-center uppercase">{heading}</h2>
                            <MainApartmentViewRedirect />
                        </Column>
                    </Row>
                </Container>
            }
        </>
    )
}

export default SearchWithRedirect2D;